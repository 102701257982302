import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchServiceAuditAnalyticsData } from '../../api/kpiQM/auditAnalytics';
import { IAuditAnalytics } from '../../types/kpiAudit/auditAnalytics';

const initialData: IAuditAnalytics = {
    lastOutletScores: [],
    outletScoresByTag: {},
    lastAuditTagComparison: { labels: [], auditIds: [], data: {} },
    tagScoresByOutlet: {},
    wasFetched: false,
};

const ServiceAuditAnalyticsContext = ProviderBuilder.buildContext<IAuditAnalytics>();

const ServiceAuditAnalyticsDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const ServiceAuditAnalyticsDataValue = ProviderBuilder.buildProvider<IAuditAnalytics>(initialData, fetchServiceAuditAnalyticsData);

    return <ServiceAuditAnalyticsContext.Provider value={ServiceAuditAnalyticsDataValue}>{children}</ServiceAuditAnalyticsContext.Provider>;
};

const useServiceAuditAnalyticsData = ProviderBuilder.buildUseHook(ServiceAuditAnalyticsContext, 'useServiceAuditAnalyticsData');

export default ServiceAuditAnalyticsDataProvider;
export { useServiceAuditAnalyticsData, ServiceAuditAnalyticsContext };
