import {
    GetOutletCustomerFeedbackResponse,
    LastOutletScore,
    LastOutletScoreResponse,
    ProductivityDataResponse,
    StoredAnalyticsDataByInterval,
    Timeslot,
} from '@mywellness-gmbh/backend-types';
import format from 'date-fns/format';
import { parse } from 'date-fns';
import { sendGetRequest } from '..';
import { loadAnalyticsData } from '../storedAnalytics';
import { GraphDataPerOutlet, IOutletComparisonData } from '../../types/kpiQuality/outletComparison';

export const fetchOutletCustomerFeedbackData = async (token: string): Promise<IOutletFeedback[]> => {
    const outletFeedbackData = await sendGetRequest<GetOutletCustomerFeedbackResponse>(
        token,
        `/statistics/outletCustomerFeedback?timeslot=${Timeslot.threeMonths}`,
    );

    return outletFeedbackData.result;
};

export const fetchAuditScores = async (token: string): Promise<LastOutletScore[][]> => {
    const auditScores = await sendGetRequest<LastOutletScoreResponse>(token, `/statistics/audit/lastOutletScores?auditType=${'service'}`);

    return auditScores.result;
};

const fetchProductivityData = async (token: string): Promise<GraphDataPerOutlet> => {
    const productivityData = await sendGetRequest<ProductivityDataResponse>(token, '/statistics/stored-productivity');
    return productivityData.result;
};
const fetchResignationInProbationary = async (
    token: string,
): Promise<{ resignationByOutlet: GraphDataPerOutlet; resignationByInterval: StoredAnalyticsDataByInterval[] }> => {
    const resignationData = await loadAnalyticsData(token, 'resignation_in_probationary', Timeslot.lastThreeMonths);
    const resignationOrdered = resignationData.result.reduce(
        (acc, analyticsData) => {
            const { interval, values } = analyticsData;
            values.forEach((value) => {
                if (!acc.data[value.identifier]) {
                    acc.data[value.identifier] = [];
                }
                acc.data[value.identifier].push({ month: format(parse(interval, 'MM/yyyy', new Date()), 'MMM'), value: value.value });
            });
            return acc;
        },
        { labels: resignationData.result[0].values.map((v) => v.identifier), data: {} } as GraphDataPerOutlet,
    );
    return { resignationByOutlet: resignationOrdered, resignationByInterval: resignationData.result };
};

export const fetchOutletComparisonData = async (token: string): Promise<IOutletComparisonData> => {
    const [outletFeedbackData, audits, productivity, resignationInProbationary] = await Promise.all([
        fetchOutletCustomerFeedbackData(token),
        fetchAuditScores(token),
        fetchProductivityData(token),
        fetchResignationInProbationary(token),
    ]);
    return {
        feedback: outletFeedbackData,
        feedbackCount: outletFeedbackData.map((feedback) => feedback.feedbackCount),
        audits,
        productivity,
        resignationInProbationary: resignationInProbationary.resignationByOutlet,
        resignationInProbationaryByInterval: resignationInProbationary.resignationByInterval,
        wasFetched: true,
    };
};
