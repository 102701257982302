import { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import { ReactComponent as CloseIcon } from '../../icons/icon__close.svg';

export interface ModalProps extends PropsWithChildren {
    onClose: () => void;
    fullscreen?: boolean;
}

const Modal: FunctionComponent<ModalProps> = ({ children, onClose: onClick, fullscreen }) => {
    const modalRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.showModal();
        }
        return () => {
            if (modalRef.current) {
                modalRef.current.close();
            }
        };
    }, []);

    return (
        <dialog
            id="modal"
            className={`w-full ${fullscreen ? '' : 'md:w-2/5'} p-8 backdrop:bg-black-100/70 rounded shadow-default`}
            ref={modalRef}
            onClose={onClick}
        >
            <div className="sticky top-0 flex justify-end z-10">
                <button
                    type="button"
                    aria-label="Close Modal"
                    className="top-0 focus-visible:outline-0"
                    onClick={onClick}
                >
                    <CloseIcon className="w-8 h-8" />
                </button>
            </div>
            {children}
        </dialog>
    );
};

export default Modal;
