import { FunctionComponent } from 'react';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const OutletResignation: FunctionComponent<GraphAnalyticsCardProps<number[]> & { selected: string }> = ({
    labels,
    data,
    title,
    selected,
}) => {
    return (
        <AnalyticsCard
            title="Kündigungsquote in der Probezeit"
            subtitle={title}
            tooltip="Alle Kündigungen durch einen Mitarbeiter während der Probezeit "
        >
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        {labels &&
                            labels.map((label) => (
                                <th key={selected + label}>
                                    <h3>{label}</h3>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {data.map((d, idx) => (
                            <td key={`valueOf${selected}${labels && labels[idx]}`}>
                                <div className=" justify-center flex"> {Math.round(d)}</div>
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </AnalyticsCard>
    );
};

export default OutletResignation;
