import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, TimeScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { FunctionComponent, useEffect } from 'react';
import LoadSpinner from '../components/LoadSpinner';
import LastScoresPerOutlet from '../components/analytics/cards/LastScoresPerOutlet';
import LastThreeScoresPerOutlet from '../components/analytics/cards/LastThreeScoresPerOutlet';
import OutletScoresByTag from '../components/analytics/cards/OutletScoresByTag';
import 'chartjs-adapter-date-fns';
import { useCustomerExpAuditAnalyticsData } from '../context/KPIQMAudit/CustomerExpAuditAnalyticsProvider';
import LastAuditTagComparison from '../components/analytics/cards/LastAuditTagComparison';
import TagRatingsByOutlet from '../components/analytics/cards/TagRatingsByOutlet';

ChartJS.register(
    LineElement,
    ArcElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
    ChartDataLabels,
    annotationPlugin,
);

const KPIQMCustomerExpAuditAnalytics: FunctionComponent = () => {
    const { data, fetchData } = useCustomerExpAuditAnalyticsData();

    useEffect(() => {
        fetchData();
    }, []);

    return data.wasFetched ? (
        <>
            <LastScoresPerOutlet
                labels={data.lastOutletScores[2].map((s) => s.displayName)}
                data={data.lastOutletScores[2]}
            />
            <LastThreeScoresPerOutlet
                type="customer"
                labels={data.lastOutletScores[0].map((s) => s.displayName)}
                data={data.lastOutletScores}
            />
            <LastAuditTagComparison
                data={data.lastAuditTagComparison}
                labels={data.lastAuditTagComparison.labels}
            />
            {Object.entries(data.outletScoresByTag).map(([outletId, outletData]) => (
                <OutletScoresByTag
                    key={outletId}
                    data={data.outletScoresByTag}
                    labels={outletData.ratings.map((r) => r.date)}
                    outletid={parseInt(outletId, 10)}
                />
            ))}
            {Object.entries(data.tagScoresByOutlet).map(([tag, tagData]) => (
                <TagRatingsByOutlet
                    key={tag}
                    data={tagData}
                    tag={tag}
                />
            ))}
        </>
    ) : (
        <LoadSpinner />
    );
};

export default KPIQMCustomerExpAuditAnalytics;
