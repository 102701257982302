/* eslint-disable max-len */
import { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRoutes } from '../../context/RouteProvider';
import MenuIcon from '../../icons/icon__distribution.svg';
import CloseIcon from '../../icons/icon__close.svg';

interface IAnalyticsProps {
    children: React.ReactNode;
    title: string;
    subtitle?: string;
}

const Analytics: FunctionComponent<IAnalyticsProps> = ({ children, title, subtitle }) => {
    const { routes, setCurrentRoute } = useRoutes();
    const [navOpenState, setNavOpenState] = useState(false);
    const [navMenuIconState, setNavMenuIconState] = useState(false);
    const toggleNav = (): void => {
        setNavOpenState(!navOpenState);
        setNavMenuIconState(!navMenuIconState);
    };

    return (
        <main>
            <div className="sticky flex flex-col sm:flex-row justify-between top-4 gap-4 z-10 px-4 md:px-10 xl:px-20">
                <button
                    type="button"
                    className="flex items-center justify-between h-12 mb-6 px-6 py-2 sm:mb-8 text-2xl rounded-full tracking-wider bg-white shadow-sm"
                    onClick={(): void => {
                        toggleNav();
                    }}
                >
                    <div>
                        <span className="max-sm:hidden uppercase">{title}</span>
                        {subtitle && (
                            <>
                                <span className="max-sm:hidden"> | </span>
                                <span>{` ${subtitle}`}</span>
                            </>
                        )}
                    </div>
                    <div>
                        <img
                            src={MenuIcon}
                            alt="menu icon"
                            className="h-5 ml-4"
                        />
                    </div>
                </button>

                <div
                    role="button"
                    aria-label="button"
                    tabIndex={0}
                    className={`${
                        navOpenState ? 'visible' : 'hidden'
                    } fixed top-0 right-0 bottom-0 left-0 bg-black-100/50 z-10 backdrop-blur-sm`}
                    onClick={(): void => {
                        setNavOpenState(false);
                    }}
                />

                <div
                    className={`absolute w-[calc(100%-2rem)] md:w-[calc(100%-5rem)] xl:w-[calc(100%-10rem)] mt-40 sm:mt-24 z-20 ${
                        navOpenState ? 'h-[min-content] px-6 py-4' : 'h-0'
                    } overflow-hidden bg-white rounded shadow-sm`}
                >
                    <div className="flex justify-end py-4">
                        <button
                            type="button"
                            className={`h-5 ${navOpenState ? 'z-20' : ''}`}
                            onClick={(): void => {
                                setNavOpenState(false);
                            }}
                        >
                            <img
                                src={CloseIcon}
                                alt="menu icon"
                                className="h-full"
                            />
                        </button>
                    </div>
                    <div className="flex flex-col">
                        {routes.map((route) => (
                            <NavLink
                                className="py-3 px-2 text-2xl hover:bg-beige-50 border-b last:border-b-0 duration-200"
                                id={route.path}
                                to={route.path}
                                key={`route_${route.path}}`}
                                onClick={(): void => {
                                    setCurrentRoute(route);
                                    toggleNav();
                                }}
                                end
                            >
                                {route.title} {route.subtitle}
                            </NavLink>
                        ))}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-[repeat(auto-fill,_minmax(37rem,_1fr))] gap-5 px-4 md:px-10 xl:px-20">
                {children}
            </div>
        </main>
    );
};

export default Analytics;
