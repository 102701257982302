import { Line } from 'react-chartjs-2';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import { OutletScoresByTag as ScoresByTag } from '@mywellness-gmbh/backend-types';
import { useRef } from 'react';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { getColorForIndex, toggleDatasets } from '../../../util';

const OutletScoresByTag: React.FC<GraphAnalyticsCardProps<Record<string, ScoresByTag>> & { outletid: number }> = ({
    data,
    labels,
    outletid,
}) => {
    const chartRef = useRef<Chart<'line'> | null>(null);

    const scoreByTagData: ChartData<'line'> = {
        labels,
        datasets: Object.entries(data[outletid].scores)
            .map(([tag, scores], idx) => ({
                label: tag,
                data: scores.map((score) => score.rating && { y: score.rating, x: new Date(score.date).getTime(), auditId: score.auditId }),
                fill: false,
                borderColor: getColorForIndex(idx),
                pointBackgroundColor: getColorForIndex(idx),
                tension: 0.4,
            }))
            .concat({
                label: 'Gesamt',
                data: data[outletid].ratings.map((rating) => ({
                    y: rating.rating,
                    x: new Date(rating.date).getTime(),
                    auditId: rating.auditId,
                })),
                fill: false,
                borderColor: '#dee9ee',
                pointBackgroundColor: '#dee9ee',
                tension: 0.4,
            }),
    };
    const lineOptions: ChartOptions<'line'> = {
        onClick(_, elements) {
            if (elements.length > 0) {
                const element = elements[0];
                const { auditId } = Object.values(data[outletid].scores)[element.datasetIndex][element.index];

                if (auditId) {
                    window.open(`http://intraapp.mywellness.de/audit/resultreport/${auditId}`, '_blank');
                }
            }
        },
        plugins: {
            tooltip: { enabled: true },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                display: false,
            },
        },

        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
            },
        },
    };

    const handleToggle = (newState: boolean): void => {
        toggleDatasets(newState, chartRef);
    };

    return (
        <AnalyticsCard
            title={data[outletid].outletName}
            tooltip="Die Bewertungen dieser Filiale der letzten 12 Monate, aufgeteilt in die verschiedenen Tags"
            onToggleDatasets={handleToggle}
        >
            <Line
                ref={chartRef}
                data={scoreByTagData}
                options={lineOptions}
            />
        </AnalyticsCard>
    );
};

export default OutletScoresByTag;
