import React, { FunctionComponent, useEffect, useState } from 'react';
import { format, getMinutes } from 'date-fns';
import calendar from '../icons/icon__calendar.svg';
import clock from '../icons/icon__clock.svg';

interface Title {
    title: string;
}

const Header: FunctionComponent<Title> = ({ title }) => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timeout = setInterval(() => {
            if (getMinutes(currentTime) !== getMinutes(new Date())) {
                setCurrentTime(new Date());
            }
        }, 1000);
        return () => clearInterval(timeout);
    }, []);

    const date = format(currentTime, 'dd.MM.yyyy');
    const currentTimeFormatted = format(currentTime, 'kk:mm');
    const currentWeek = format(currentTime, 'I');

    return (
        <header className="flex justify-between px-4 pt-8 md:px-10 xl:px-20 xl:pt-16">
            <h1 className="text-3xl font-medium uppercase tracking-wider">{title}</h1>
            <div className="hidden lg:flex">
                <div className="flex ml-12">
                    <div className="hidden xl:block">
                        <img
                            className="mr-8 w-full h-5 translate-y-1"
                            src={calendar}
                            alt=""
                        />
                    </div>
                    <span className="text-2xl font-medium tracking-widest">{date}</span>
                </div>
                <div className="flex ml-8">
                    <div className="hidden xl:block">
                        <img
                            className="mr-8 w-full h-5 translate-y-1"
                            src={clock}
                            alt=""
                        />
                    </div>
                    <span className="hidden xl:block mr-8 text-2xl font-medium tracking-wider">{currentTimeFormatted}</span>
                    <span className="text-2xl font-medium tracking-wider">KW {currentWeek}</span>
                </div>
            </div>
        </header>
    );
};

export default Header;
