import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { LastOutletScore } from '@mywellness-gmbh/backend-types';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { arrayAverage } from '../../../util';

const LastThreeAudits: React.FC<GraphAnalyticsCardProps<Partial<LastOutletScore>[]>> = ({ labels, data }) => {
    const chartData: ChartData<'bar'> = {
        labels,
        datasets: [
            {
                data: data.map((outlet) => outlet.rating || null),
                backgroundColor: '#558ba0',
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };
    const chartOptions: ChartOptions<'bar'> = {
        onClick(_, elements) {
            if (elements.length > 0) {
                const element = elements[0];
                const { auditId } = data[element.index];
                if (auditId) {
                    window.open(`http://intraapp.mywellness.de/audit/resultreport/${auditId}`, '_blank');
                }
            }
        },
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
                formatter(value) {
                    if (!value) return value;
                    return value.toFixed(0);
                },
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: data.map(() => {
                    const avrg = arrayAverage(data.map((outlet) => outlet.rating));
                    return {
                        type: 'line',
                        yMin: avrg,
                        yMax: avrg,
                        borderDash: [10, 10],
                    };
                }),
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                max: 100,
                stacked: true,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard title="Letzte 3 Audits">
            <Bar
                data={chartData}
                options={chartOptions}
            />
        </AnalyticsCard>
    );
};

export default LastThreeAudits;
