import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchCRMComparisonData } from '../../api/kpiCRM/crmComparison';

const initialData: ICRMComparisonData = {
    feedback: [],
    bookingsByOutlet: [],
    outletDiscounts: [],
    feedbackCount: [],
    wasFetched: false,
};

const CRMComparisonDataContext = ProviderBuilder.buildContext<ICRMComparisonData>();

const CRMComparisonProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const kpiQualityDataValue = ProviderBuilder.buildProvider<ICRMComparisonData>(initialData, fetchCRMComparisonData);

    return <CRMComparisonDataContext.Provider value={kpiQualityDataValue}>{children}</CRMComparisonDataContext.Provider>;
};

const useCRMComparisonData = ProviderBuilder.buildUseHook(CRMComparisonDataContext, 'useCRMComparisonData');

export default CRMComparisonProvider;
export { useCRMComparisonData, CRMComparisonDataContext };
