import { FunctionComponent, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { arrayAverage, getColorForIndex, toggleDatasets } from '../../../util';

const TagRatingsByOutlet: FunctionComponent<
    GraphAnalyticsCardProps<{ outletDisplayname: string; ratings: Array<{ date: number; rating: number | null; auditId: number }> }[]> & {
        tag: string;
    }
> = ({ data, tag }) => {
    const chartRef = useRef<Chart<'line', Array<{ date: number; rating: number | null; auditId: number }>> | null>(null);

    const allTagsData: ChartData<'line', Array<{ date: number; rating: number | null; auditId: number }>> = {
        datasets: data.map((outlet, index) => ({
            label: outlet.outletDisplayname,
            data: outlet.ratings,
            fill: false,
            borderColor: getColorForIndex(index),
            pointBackgroundColor: getColorForIndex(index),
            tension: 0.4,
        })),
    };
    const handleToggle = (newState: boolean): void => {
        toggleDatasets(newState, chartRef);
    };
    const allTagsOptions: ChartOptions<'line'> = {
        onClick(_, elements) {
            if (elements.length > 0) {
                const element = elements[0];
                const { auditId } = data[element.datasetIndex].ratings[element.index];
                if (auditId) {
                    window.open(`http://intraapp.mywellness.de/audit/resultreport/${auditId}`, '_blank');
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                labels: {
                    usePointStyle: true,
                },
            },
            annotation: {
                annotations: [
                    {
                        type: 'line',
                        yMin: data.reduce((acc, outlet) => acc + arrayAverage(outlet.ratings.map((v) => v.rating)), 0) / data.length,
                        yMax: data.reduce((acc, outlet) => acc + arrayAverage(outlet.ratings.map((v) => v.rating)), 0) / data.length,
                        borderDash: [10, 10],
                    },
                ],
            },
        },
        parsing: {
            xAxisKey: 'date',
            yAxisKey: 'rating',
        },

        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title={tag}
            tooltip={`Bewertungen von allen Filialen über die letzten 12 Monate von der Kategorie ${tag}`}
            onToggleDatasets={handleToggle}
        >
            <Line
                ref={chartRef}
                data={allTagsData}
                options={allTagsOptions}
            />
        </AnalyticsCard>
    );
};

export default TagRatingsByOutlet;
