import { FunctionComponent } from 'react';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { StoredAnalyticsMonthlyDataRecord } from '../../../types/kpiQuality/outletComparison';

const ResignationInProbationary: FunctionComponent<GraphAnalyticsCardProps<StoredAnalyticsMonthlyDataRecord>> = ({
    labels,
    data,
    fetchData,
}) => {
    const lastData = data && Object.values(data).map((value) => value.slice(-1)[0]);
    return (
        <AnalyticsCard
            title="Kündigungsquote in der Probezeit"
            subtitle="der Filialen"
            tooltip="Alle Kündigungen durch einen Mitarbeiter während der Probezeit "
            onSaveEditableConfig={fetchData && fetchData}
            editableConfigIdentifier="resignation_in_probationary"
        >
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        {labels &&
                            labels.map((label) => (
                                <th key={label}>
                                    <h3>{label}</h3>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {lastData.map((d) => (
                            <td key={`valueOf${d.month}`}>
                                <div className=" flex justify-center">{d.value}</div>
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </AnalyticsCard>
    );
};

export default ResignationInProbationary;
