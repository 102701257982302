import { Chart, ChartTypeRegistry } from 'chart.js';
import { format, startOfMonth, subMonths } from 'date-fns';
import { RefObject } from 'react';
/**
 * Clamp a number between a min and max value
 *
 * @param num The number to clamp
 * @param min The minimum value
 * @param max The maximum value
 * @returns The clamped number
 */
const clamp = (num: number, min: number, max: number): number => Math.min(Math.max(num, min), max);

/**
 * Get the name of a month by its number
 *
 * @param month The month number (1-12)
 * @returns The name of the month
 */
const getNameOfMonth = (month: number): string => {
    switch (month) {
        case 1:
            return 'Januar';
        case 2:
            return 'Februar';
        case 3:
            return 'März';
        case 4:
            return 'April';
        case 5:
            return 'Mai';
        case 6:
            return 'Juni';
        case 7:
            return 'Juli';
        case 8:
            return 'August';
        case 9:
            return 'September';
        case 10:
            return 'Oktober';
        case 11:
            return 'November';
        case 12:
            return 'Dezember';
        default:
            throw new Error('Invalid month number');
    }
};

/**
 * Return the average of an array of numbers
 *
 * @param arr The array of numbers
 * @returns The average of the array
 */
const arrayAverage = (arr: (number | null | undefined)[]): number => {
    let valuesLength = 0;
    if (arr.length === 0) return 0;
    const sum =
        arr.reduce<number>((p, c) => {
            if (!c) return p;
            valuesLength += 1;
            return p + c;
        }, 0) || 0;
    if (valuesLength === 0) return 0;
    return sum / valuesLength;
};

type IFormatNumberOptions = {
    useThousandsSeparator?: boolean;
    thousandSeparator?: string;
    decimalSeparator?: string;
    decimalPlaces?: number;
};

/**
 * Format a number to a string with thousand and decimal separators
 *
 * @param num The number to format
 * @param options The options to use for formatting
 * @returns The string representation of the formatted number
 */
const formatNumber = (num: number, options?: IFormatNumberOptions): string => {
    const useThousandsSeparator = options?.useThousandsSeparator ?? true;
    const thousandSeparator = options?.thousandSeparator ?? '.';
    const decimalSeparator = options?.decimalSeparator ?? ',';
    const decimalPlaces = clamp(options?.decimalPlaces ?? 2, 0, 20);

    const decimals = num % 1;
    const integer = Math.floor(num);

    let res: string = integer.toString();
    if (useThousandsSeparator) {
        res = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    }

    if (decimalPlaces > 0) {
        const decimalsString = Math.floor(decimals * 10 ** decimalPlaces).toString();
        if (decimalsString.length < decimalPlaces) {
            res = `${res}${decimalSeparator}${decimalsString.padEnd(decimalPlaces, '0')}`;
        } else {
            res = `${res}${decimalSeparator}${decimalsString}`;
        }
    }

    return res;
};

/**
 * Toggle all dataset visibilities in a given chart
 *
 * @param newState The new visibility state
 * @param chart The chart to toggle the datasets in
 *
 * @returns void
 */
const toggleDatasets = (newState: boolean, chart: RefObject<Chart<keyof ChartTypeRegistry, unknown>>): void => {
    const { current } = chart;
    if (!current) return;
    current.data.datasets?.forEach((_, idx) => {
        current.getDatasetMeta(idx).hidden = !newState;
    });
    current.update();
};

/**
 * Return a color for a given index
 *
 * @param index The index to get the color for
 * @returns The color string
 */
const getColorForIndex = (index: number): string => {
    const colors = ['#558ba0', '#edcd77', '#eb6253', '#738B7A', '#90AF76', '#2d435b', '#a1c1ce', '#702963', '#5D3FD3', '#51414F'];
    return colors[index % colors.length];
};

/**
 * Return a color for a given rating
 *
 * @param rating The rating to get the color for
 * @returns The color string
 */
const getColorFromRating = (rating: number | null): string => {
    if (!rating) return '#e6765a';
    if (rating >= 90) return '#90AF76';
    if (rating >= 65) return '#edcd77e6';
    return '#e6765a';
};

/**
 * Get the last three months as an array of strings
 *
 * @returns The last three months as an array of strings
 */
function getLastThreeMonths(): string[] {
    const today = new Date();
    const currentMonthStart = startOfMonth(today);

    // Array to hold the last three months
    const lastThreeMonths: string[] = [];

    // Get the previous three months
    for (let i = 3; i >= 1; i -= 1) {
        const previousMonth = subMonths(currentMonthStart, i);
        lastThreeMonths.push(format(previousMonth, 'MMM'));
    }

    return lastThreeMonths;
}

export { formatNumber, clamp, arrayAverage, getNameOfMonth, getColorForIndex, getColorFromRating, toggleDatasets, getLastThreeMonths };
