import { FunctionComponent } from 'react';
import { OutletDiscount } from '@mywellness-gmbh/backend-types';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const DiscountsCRMAndOnLocation: FunctionComponent<GraphAnalyticsCardProps<OutletDiscount>> = ({ title, subtitle, data }) => {
    return (
        <AnalyticsCard
            title={title || 'Feedback'}
            subtitle={subtitle}
        >
            <div className="flex justify-evenly gap-x-12 px-8">
                <div className="flex-1">
                    <h2 className="mb-4 font-medium text-2xl">CRM-Rabatte</h2>
                    <div className="flex gap-4 justify-between text-xl">
                        <h3 className="">Rabatt</h3>
                        <p className="font-medium">{data.crmCount}</p>
                    </div>
                    <div className="flex gap-4 justify-between text-xl">
                        <h3>Absage</h3>
                        <p className="font-medium">{data.crmDiscount}</p>
                    </div>
                </div>
                <div className="flex-1">
                    <h2 className="mb-4 font-medium text-2xl">Rabatte vor Ort</h2>
                    <div className="flex gap-4 justify-between text-xl">
                        <h3>Rabatt</h3>
                        <p className="font-medium">{data.onSiteDiscount}</p>
                    </div>
                </div>
            </div>
        </AnalyticsCard>
    );
};

export default DiscountsCRMAndOnLocation;
