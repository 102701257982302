import { FunctionComponent, useEffect, useState } from 'react';

interface IFilterTabProps {
    outlet: string;
    isSelected?: boolean;
    onClick: (outlet: string) => void;
}

const FilterTab: FunctionComponent<IFilterTabProps> = ({ outlet, isSelected, onClick }) => {
    const [selected, setSelected] = useState(isSelected);

    useEffect(() => {
        setSelected(isSelected);
    }, [isSelected]);
    return (
        <button
            type="button"
            // eslint-disable-next-line max-len
            className={`px-8 py-2 border border-dark-chocolate-100 rounded-full hover:border-apricot-100/75 hover:bg-apricot-100/75 ${selected ? 'bg-apricot-100 border-apricot-100' : ''}`}
            onClick={(): void => {
                onClick(outlet);
                setSelected(!selected);
            }}
        >
            {outlet}
        </button>
    );
};

export default FilterTab;
