import { Outlet } from '@mywellness-gmbh/backend-types';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { getOutletData } from '../api/outletData';

type OutletContextType = {
    outlets: Outlet[];
    setOutlets: (outlets: Outlet[]) => void;
    clearOutlets: () => void;
};

const OutletContext = createContext<OutletContextType | undefined>(undefined);

const OutletProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [outlets, setOutlets] = useState<Outlet[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const outletData = await getOutletData();
            if (outletData.length > 0) {
                setOutlets(outletData);
            }
        })();
    }, []);

    const clearOutlets = (): void => {
        setOutlets([]);
    };

    const outletValue = useMemo(
        () => ({
            outlets,
            setOutlets,
            clearOutlets,
        }),
        [outlets, setOutlets, clearOutlets],
    );

    return <OutletContext.Provider value={outletValue}>{children}</OutletContext.Provider>;
};

const useOutlets = (): OutletContextType => {
    const context = useContext(OutletContext);
    if (context === undefined) {
        throw new Error('useOutlet must be used within a OutletProvider');
    }
    return context;
};

export default OutletProvider;
export { useOutlets, OutletContext };
