import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchOutletComparisonData } from '../../api/kpiQuality/outletComparison';
import { IOutletComparisonData } from '../../types/kpiQuality/outletComparison';

const initialData: IOutletComparisonData = {
    feedback: [],
    audits: [],
    resignationInProbationary: {
        labels: [],
        data: {},
    },
    resignationInProbationaryByInterval: [],
    productivity: {
        labels: [],
        data: {},
    },
    feedbackCount: [],
    wasFetched: false,
};

const OutletComparisonDataContext = ProviderBuilder.buildContext<IOutletComparisonData>();

const OutletComparisonProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const kpiQualityDataValue = ProviderBuilder.buildProvider<IOutletComparisonData>(initialData, fetchOutletComparisonData);

    return <OutletComparisonDataContext.Provider value={kpiQualityDataValue}>{children}</OutletComparisonDataContext.Provider>;
};

const useOutletComparisonData = ProviderBuilder.buildUseHook(OutletComparisonDataContext, 'useOutletComparisonData');

export default OutletComparisonProvider;
export { useOutletComparisonData, OutletComparisonDataContext };
