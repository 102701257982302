import { FunctionComponent, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilterTab from './navigation/FilterTab';
import { useOutlets } from '../context/OutletProvider';

const TabSelector: FunctionComponent = () => {
    const outletData = useOutlets();
    const [params, setParams] = useSearchParams('selected');
    const selected = useMemo(() => {
        const selectedParam = params.get('selected');
        if (selectedParam === null || selectedParam.length === 0) {
            return [];
        }
        return selectedParam.split(',');
    }, [params]);
    const outlets = useMemo(() => outletData.outlets.map((outlet) => outlet.displayName).concat('Gesamt'), [outletData.outlets]);

    return (
        <div className="flex flex-wrap gap-4 mb-8 px-4 md:px-10 xl:px-20">
            {outlets.map((outlet) => {
                return (
                    <FilterTab
                        key={outlet}
                        outlet={outlet}
                        isSelected={selected?.includes(outlet)}
                        onClick={(o): void => {
                            if (selected?.includes(o)) {
                                if (selected.length === 1) {
                                    setParams((prev) => {
                                        prev.delete('selected');
                                        return prev;
                                    });
                                    return;
                                }
                                setParams((prev) => ({ ...prev, selected: selected.filter((item) => item !== o).join(',') }));
                            } else {
                                setParams((prev) => ({ ...prev, selected: o })); // TODO: Make multiple selection possible
                            }
                        }}
                    />
                );
            })}
        </div>
    );
};

export default TabSelector;
