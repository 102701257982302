import { ShopRevenueByInterval } from '@mywellness-gmbh/backend-types/backend/types/statistics/shop';
import clsx from 'clsx';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

type Props = {
    lastIntervalWord: string;
    currentIntervalWord: string;
};

const LastTwoShopRevenueInterval: React.FC<GraphAnalyticsCardProps<ShopRevenueByInterval[]> & Props> = ({
    data,
    title,
    lastIntervalWord,
    currentIntervalWord,
}) => {
    const onlyOneData = data.length === 1;
    return (
        <AnalyticsCard title={title || ''}>
            <table className=" table-auto w-full">
                <thead>
                    <tr>
                        <th
                            className=" w-1/4 bg-macchiato-100 text-right p-1"
                            aria-label="empty field"
                        >
                            &nbsp;
                        </th>
                        <th className="text-lg w-1/4 bg-macchiato-100 text-right p-1">
                            <h3>Merchandise</h3>
                        </th>
                        <th className="text-lg w-1/4 bg-macchiato-100 text-right p-1">
                            <h3>Voucher</h3>
                        </th>
                        <th className="text-lg w-1/4 bg-macchiato-100 text-right p-1">
                            <h3>Misc</h3>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        className={clsx({
                            'font-bold': onlyOneData,
                        })}
                    >
                        <td className=" text-start bg-beige-100 p-1 mx-2 ">
                            <div>{onlyOneData ? currentIntervalWord : lastIntervalWord}</div>
                        </td>
                        <td className="bg-beige-100 p-1 mx-2 text-right">
                            <div>{data[0]?.merchandise.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</div>
                        </td>
                        <td className="bg-beige-100 p-1 mx-2 text-right">
                            <div>{data[0]?.voucher.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</div>
                        </td>
                        <td className="bg-beige-100 p-1 mx-2 text-right">
                            <div>{data[0]?.misc.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</div>
                        </td>
                    </tr>
                    {data[1] && (
                        <tr className=" font-bold">
                            <td className=" text-start bg-beige-100 p-1 mx-2">
                                <div>{currentIntervalWord}</div>
                            </td>
                            <td className="bg-beige-100 p-1 mx-2 text-right">
                                <div>{data[1].merchandise.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</div>
                            </td>
                            <td className="bg-beige-100 p-1 mx-2 text-right">
                                <div>{data[1].voucher.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</div>
                            </td>
                            <td className="bg-beige-100 p-1 mx-2 text-right">
                                <div>{data[1].misc.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </AnalyticsCard>
    );
};

export default LastTwoShopRevenueInterval;
