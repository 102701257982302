import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomerData from '../pages/CustomerData';
import KPIMarketingAdFrequency from '../pages/KPIMarketingAdFrequency';
import KPIMarketingAdSalesVolume from '../pages/KPIMarketingAdSalesVolume';
import KPIMarketingAdBookingRate from '../pages/KPIMarketingAdBookingRate';
import KPIMRO from '../pages/KPIMRO';
import KPIPeopleCultureFluctuation from '../pages/KPIPeopleCultureFluctuation';
import KPIOperations from '../pages/KPIOperations';
import KPIOutletStatistics from '../pages/KPIOutletStatistics';
import KPIQMServiceAuditAnalytics from '../pages/KPIQMServiceAuditAnalytics';
import KPIQMCustomerExpAuditAnalytics from '../pages/KPIQMCustomerExpAuditAnalytics';
import { useCustomerData } from './CustomerDataProvider';
import { useSalesVolumeData } from './KPIMarketing/SalesVolumeProvider';
import { useBookingRateData } from './KPIMarketing/BookingRateProvider';
import { useFrequencyData } from './KPIMarketing/FrequencyProvider';
import { useFluctuationPage } from './KPIPeopleAndCulture/FluctuationProvider';
import { useOperationsPage } from './KPIOperations/OperationsProvider';
import { useKpiMroData } from './KPIMro/KPIMroProvider';
import { useOutletComparisonData } from './KPIQuality/KPIOutletComparisonProvider';
import { useServiceAuditAnalyticsData } from './KPIQMAudit/ServiceAuditAnalyticsProvider';
import { useCustomerExpAuditAnalyticsData } from './KPIQMAudit/CustomerExpAuditAnalyticsProvider';
import KPIOutletComparison from '../pages/KPIOutletComparison';
import KPICRMComparison from '../pages/KPICRMComparison';
import { useCRMComparisonData } from './KPICRM/KPICRMComparisonProvider';
import { useShopRevenueData } from './KPIMarketing/ShopRevenueProvider';
import KPIMarketingShopRevenue from '../pages/KPIMarketingShopRevenue';

type RouteContextType = {
    routes: IRoute[];
    setRoutes: (routes: IRoute[]) => void;
    clearRoutes: () => void;
    currentRoute: IRoute;
    setCurrentRoute: (route: IRoute) => void;
    moveToNextRoute: () => void;
    nextRoute: IRoute;
};

const RoutesContext = createContext<RouteContextType | undefined>(undefined);

const RoutesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const allRoutes: IRoute[] = [
        {
            path: '/kpi-marketing-ad-frequenz',
            component: <KPIMarketingAdFrequency />,
            isActive: true,
            title: 'KPI Marketing/AD',
            subtitle: 'Frequenz',
            useHook: useFrequencyData(),
        },
        {
            path: '/kpi-marketing-ad-umsatz',
            component: <KPIMarketingAdSalesVolume />,
            isActive: true,
            title: 'KPI Marketing/AD',
            subtitle: 'Umsatz',
            useHook: useSalesVolumeData(),
        },
        {
            path: '/kpi-marketing-ad-buchungsquote',
            component: <KPIMarketingAdBookingRate />,
            isActive: true,
            title: 'KPI Marketing/AD',
            subtitle: 'Buchungen',
            useHook: useBookingRateData(),
        },
        {
            path: '/kpi-marketing-shop-revenue',
            component: <KPIMarketingShopRevenue />,
            isActive: true,
            title: 'KPI Marketing',
            subtitle: 'Shop Umsatz',
            useHook: useShopRevenueData(),
        },
        {
            path: '/kpi-mro',
            component: <KPIMRO />,
            isActive: true,
            title: 'KPI MRO',
            useHook: useKpiMroData(),
        },
        {
            path: '/kpi-people-culture-fluktuation',
            component: <KPIPeopleCultureFluctuation />,
            isActive: true,
            title: 'KPI People and Culture',
            subtitle: 'Fluktuation',
            useHook: useFluctuationPage(),
        },
        {
            path: '/kpi-operations',
            component: <KPIOperations />,
            isActive: true,
            title: 'KPI',
            subtitle: 'Operations',
            useHook: useOperationsPage(),
        },
        {
            path: '/customer-data',
            component: <CustomerData />,
            isActive: true,
            title: 'KPI CRM',
            subtitle: 'Customer',
            useHook: useCustomerData(),
        },
        {
            path: '/kpi-service-audit-analytics',
            component: <KPIQMServiceAuditAnalytics />,
            isActive: true,
            title: 'KPI QM',
            subtitle: 'SE Audit Analytics',
            useHook: useServiceAuditAnalyticsData(),
        },
        {
            path: '/kpi-customer-exp-audit-analytics',
            component: <KPIQMCustomerExpAuditAnalytics />,
            isActive: true,
            title: 'KPI QM',
            subtitle: 'CE Audit Analytics',
            useHook: useCustomerExpAuditAnalyticsData(),
        },
        {
            path: '/crm-comparison',
            component: <KPICRMComparison />,
            isActive: true,
            title: 'KPI CRM',
            subtitle: 'Filialstatistiken',
            useHook: useCRMComparisonData(),
        },
        {
            path: '/outlet-comparison',
            component: <KPIOutletComparison />,
            isActive: true,
            title: 'KPI QM',
            subtitle: 'Filialvergleich',
            useHook: useOutletComparisonData(),
        },
        {
            path: '/outlet-statistics',
            component: <KPIOutletStatistics />,
            isActive: true,
            title: 'KPI QM',
            subtitle: 'Filialstatistiken',
            useHook: useOutletComparisonData(),
        },
    ];

    const [routesState, setRoutesState] = useState<IRoute[]>(allRoutes.filter((route) => route.isActive));
    const [currentRouteState, setCurrentRouteState] = useState<IRoute>(allRoutes.filter((route) => route.isActive)[0]);
    const nextRoute = useRef<IRoute>(allRoutes.filter((route) => route.isActive)[1]);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const setRoutes = useCallback((routes: IRoute[]): void => {
        setRoutesState(routes);
    }, []);

    const setCurrentRoute = useCallback((route: IRoute): void => {
        setCurrentRouteState(route);
        const routeId = routesState.findIndex((r) => r.path === route.path);

        if (routeId + 1 === routesState.length) {
            // we are on the last route, so go back to the first route
            // eslint-disable-next-line prefer-destructuring
            nextRoute.current = routesState[0];
        } else {
            nextRoute.current = routesState[routeId + 1];
        }
    }, []);

    const clearRoutes = useCallback((): void => {
        setRoutesState([]);
    }, []);

    useEffect(() => {
        // initialize the current route correctly
        const route = routesState.find((r) => r.path === window.location.pathname.split('?')[0]);
        if (route) {
            setCurrentRoute(route);
        }
    }, []);

    const moveToNextRoute = useCallback((): void => {
        // go to the next route
        let newPath = nextRoute.current.path;
        if (searchParams.toString()) {
            newPath += `?${searchParams.toString()}`;
        }
        navigate(newPath);
        setCurrentRoute(nextRoute.current);
    }, []);

    const routesValue = useMemo(
        () => ({
            routes: routesState,
            setRoutes,
            clearRoutes,
            currentRoute: currentRouteState,
            setCurrentRoute,
            moveToNextRoute,
            nextRoute: nextRoute.current,
        }),
        [routesState, setRoutes, clearRoutes, currentRouteState, setCurrentRoute, moveToNextRoute, nextRoute],
    );

    return <RoutesContext.Provider value={routesValue}>{children}</RoutesContext.Provider>;
};

const useRoutes = (): RouteContextType => {
    const routeContext = useContext(RoutesContext);
    if (!routeContext) {
        throw new Error('useRoutes must be used within a RoutesProvider');
    }
    return routeContext;
};

export default RoutesProvider;
export { RoutesContext, useRoutes };
