import { GetShopRevenueResponse, Timeslot } from '@mywellness-gmbh/backend-types';
import { sendGetRequest } from '..';
import { IShopRevenueData } from '../../types/kpiMarketing/shopRevenue';

const fetchShopRevenueData = async (token: string): Promise<IShopRevenueData> => {
    const monthlyRevenue = sendGetRequest<GetShopRevenueResponse>(token, `/statistics/shop/revenue?timeslot=${Timeslot.threeMonths}`);
    const weeklyRevenue = sendGetRequest<GetShopRevenueResponse>(token, `/statistics/shop/revenue?timeslot=${Timeslot.threeMonthsInWeeks}`);
    const dailyRevenue = sendGetRequest<GetShopRevenueResponse>(token, `/statistics/shop/revenue?timeslot=${Timeslot.threeMonthsInDays}`);
    const hourlyRevenue = sendGetRequest<GetShopRevenueResponse>(token, `/statistics/shop/revenue?timeslot=${Timeslot.fourtyeightHours}`);

    const [monthly, weekly, daily, hourly] = await Promise.all([monthlyRevenue, weeklyRevenue, dailyRevenue, hourlyRevenue]);

    return {
        monthly: monthly.result,
        weekly: weekly.result,
        daily: daily.result,
        hourly: hourly.result,
        wasFetched: true,
    };
};

export { fetchShopRevenueData };
