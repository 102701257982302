import { FunctionComponent, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { TagComparisonData } from '../../../types/kpiAudit/auditAnalytics';
import { arrayAverage, getColorForIndex, toggleDatasets } from '../../../util';

const LastAuditTagComparison: FunctionComponent<GraphAnalyticsCardProps<TagComparisonData>> = ({ data, labels }) => {
    const [average, setAverage] = useState<number | null>(null);
    const chartRef = useRef<Chart<'bar'>>(null);
    const lastTagsData: ChartData<'bar', (number | null)[]> = {
        labels,
        datasets: Object.entries(data.data).map(([key, value], idx) => ({
            label: key,
            data: value,
            backgroundColor: getColorForIndex(idx),
            datalabels: {
                font: {
                    size: 16,
                },
                color: 'black',
            },
        })),
    };

    const handleToggle = (newState: boolean): void => {
        toggleDatasets(newState, chartRef);
    };

    const lastTagsOptions: ChartOptions<'bar'> = {
        onClick(_, elements) {
            if (elements.length > 0) {
                const element = elements[0];
                const auditId = data.auditIds[element.index];
                if (auditId) {
                    window.open(`http://intraapp.mywellness.de/audit/resultreport/${auditId}`, '_blank');
                }
            }
        },
        plugins: {
            tooltip: { enabled: true },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
                onClick(e, legendItem, legend) {
                    Chart.defaults.plugins.legend.onClick.call(this, e, legendItem, this);
                    const active = legend.legendItems?.filter((item) => !item.hidden) || [];
                    if (active.length > 1) return setAverage(null);
                    if (active.length === 0) return setAverage(null);
                    setAverage(arrayAverage(data.data[active[0].text]));
                    return null;
                },
            },
            datalabels: {
                display: !!average,
                anchor: 'end',
                align: 'end',
                offset: -4,
                formatter(value) {
                    return Math.round(value);
                },
            },
            annotation: {
                annotations: average
                    ? [
                          {
                              type: 'line',
                              yMin: average,
                              yMax: average,
                              borderDash: [10, 10],
                          },
                      ]
                    : [],
            },
        },
        parsing: {
            yAxisKey: 'rating',
            xAxisKey: 'outlet',
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Tag Vergleich vom letzten Audit"
            tooltip="Die Bewertungen des letzen Audits aus jeder Filiale, aufgeteilt in die Tags"
            onToggleDatasets={handleToggle}
        >
            <Bar
                ref={chartRef}
                data={lastTagsData}
                options={lastTagsOptions}
            />
        </AnalyticsCard>
    );
};

export default LastAuditTagComparison;
