import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import LoadSpinner from '../components/LoadSpinner';
import OutletCustomerFeedback from '../components/analytics/cards/OutletCustomerFeedback';
import StayAndBookingPerPhone from '../components/analytics/cards/StayAndBookingPerPhone';
import DiscountsCRMAndOnLocation from '../components/analytics/cards/DiscoutsCRMAndOnLocation';
import TabSelector from '../components/TabSelector';
import { useCRMComparisonData } from '../context/KPICRM/KPICRMComparisonProvider';

ChartJS.register(LineElement, BarElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels);

const prepareSelectedData = (data: ICRMComparisonData, selected?: string[] | null): SelectedOutletData => {
    if (!selected || selected[0] === '' || selected[0] === 'Gesamt') {
        const totalFeedbacks = data.feedback.reduce<Record<string, Record<string, number>>>((acc, outlet) => {
            Object.entries(outlet.feedbackScores).forEach(([type, scores]) => {
                scores.forEach((score) => {
                    if (!acc[type]) {
                        acc[type] = {};
                    }
                    if (!acc[type][score.month]) {
                        acc[type][score.month] = 0;
                    }
                    acc[type][score.month] += score.value;
                });
            });
            return acc;
        }, {});

        const feedback = Object.entries(totalFeedbacks).reduce<Record<string, { month: string; value: number }[]>>((acc, [type, entry]) => {
            acc[type] = Object.entries(entry).map(([month, value]) => ({ month, value: value / data.feedback.length }));
            return acc;
        }, {});

        const sumCrmDiscount = data.outletDiscounts.reduce((sum, outlet) => sum + outlet.crmDiscount, 0);
        const sumOnSiteDiscount = data.outletDiscounts.reduce((sum, outlet) => sum + outlet.onSiteDiscount, 0);
        const sumCrmCount = data.outletDiscounts.reduce((sum, outlet) => sum + outlet.crmCount, 0);

        const sumStays = data.bookingsByOutlet.reduce((sum, outlet) => sum + outlet.bookings, 0);
        const sumBookings = data.bookingsByOutlet.reduce((sum, outlet) => sum + outlet.viaPhone, 0);
        const sumRevenue = data.bookingsByOutlet.reduce((sum, outlet) => sum + outlet.revenue, 0);

        const totalFeedbackCount = data.feedbackCount.reduce((sum, count) => sum + count, 0);

        return {
            feedback,
            discount: {
                outletId: 0,
                displayName: '',
                crmDiscount: sumCrmDiscount || 0,
                onSiteDiscount: sumOnSiteDiscount || 0,
                crmCount: sumCrmCount || 0,
            },
            sales: { stays: sumStays || 0, bookings: sumBookings || 0, revenue: sumRevenue || 0 },
            feedbackCount: totalFeedbackCount || 0,
        };
    }
    const discount = data.outletDiscounts.filter((outlet) => outlet.displayName === selected[0])[0];
    const salesData = data.bookingsByOutlet.filter((outlet) => outlet.displayName === selected[0])[0];
    const sales = { stays: salesData?.bookings, bookings: salesData?.viaPhone, revenue: salesData?.revenue };
    const feedback = data.feedback.filter((f) => f.displayName === selected[0])[0] || {};
    return { feedback: feedback?.feedbackScores, sales, discount, feedbackCount: feedback?.feedbackCount || 0 };
};

const KPICRMComparison: FunctionComponent = () => {
    const { data, fetchData } = useCRMComparisonData();
    const [selected] = useSearchParams('selected');
    const selectedData = useMemo(() => prepareSelectedData(data, selected.get('selected')?.split(',')), [data, selected]);

    useEffect(() => {
        fetchData();
    }, []);

    if (!data.wasFetched) {
        return <LoadSpinner />;
    }

    return (
        <>
            <div className="col-span-full">
                <TabSelector />
            </div>
            <OutletCustomerFeedback
                data={selectedData.feedback}
                title="Kundenfeedback"
                subtitle={`${selectedData.feedbackCount} Bewertungen`}
            />
            <StayAndBookingPerPhone
                title="Aufenthalt und Buchungen"
                data={selectedData.sales}
            />
            <DiscountsCRMAndOnLocation
                title="Rabatte"
                data={selectedData.discount}
            />
        </>
    );
};

export default KPICRMComparison;
