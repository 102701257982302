import { ShopRevenueByInterval } from '@mywellness-gmbh/backend-types/backend/types/statistics/shop';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import clsx from 'clsx';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { getColorForIndex } from '../../../util';

type ShopRevenueProps = GraphAnalyticsCardProps<ShopRevenueByInterval[]> & {
    onIntervalChange: (interval: IntervalOption) => void;
    chosenInterval: IntervalOption;
};

const options: IntervalOption[] = [
    { label: 'Monat', value: 'monthly' },
    { label: 'Woche', value: 'weekly' },
    { label: 'Tag', value: 'daily' },
    { label: 'Stunde', value: 'hourly' },
];
const ShopRevenueGraph: React.FC<ShopRevenueProps> = ({ data, title, onIntervalChange, chosenInterval }) => {
    const mappedData = data.reduce<Record<string, GraphData[]>>((acc, curr) => {
        const { interval, ...rest } = curr;
        Object.entries(rest).forEach(([key, value]) => {
            if (acc[key]) {
                acc[key].push({ y: value, x: new Date(interval).getTime() });
            } else {
                acc[key] = [{ y: value, x: new Date(interval).getTime() }];
            }
        });
        return acc;
    }, {});
    const chartData: ChartData<'line', GraphData[]> = {
        datasets: Object.entries(mappedData).map(([key, value], idx) => ({
            label: key,
            data: value,
            backgroundColor: getColorForIndex(idx),
            borderColor: getColorForIndex(idx),
        })),
    };
    const chartOptions: ChartOptions<'line'> = {
        plugins: {
            datalabels: {
                display: false,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            x: {
                type: 'time',
            },
        },

        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard title={title || ''}>
            <div className=" flex flex-row absolute">
                {options.map((option) => (
                    <button
                        key={option.value}
                        className={clsx(
                            `btn btn-primary p-1 m-1 border border-gray-300 roundedflex items-center justify-center
                            px-4 py-1 gap-2 rounded-full text-sm tracking-wider shadow-sm bg-white`,
                            { 'bg-beige': option.value === chosenInterval.value },
                        )}
                        onClick={(): void => onIntervalChange(option)}
                        type="button"
                    >
                        {option.label}
                    </button>
                ))}
            </div>
            <Line
                data={chartData}
                options={chartOptions}
            />
        </AnalyticsCard>
    );
};

export default ShopRevenueGraph;

type GraphData = { y: number; x: number };

export type IntervalOption = {
    label: string;
    value: 'monthly' | 'weekly' | 'daily' | 'hourly';
};
