import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Analytics from '../components/analytics/Analytics';

import OutletCustomerFeedback from '../components/analytics/cards/OutletCustomerFeedback';
import { useViewOutletStatsData } from '../context/ViewOutletStatsProvider';
import LastThreeAudits from '../components/analytics/cards/LastThreeAudits';
import ProductivityLastMonths from '../components/analytics/cards/ProductivityLastMonths';
import OutletResignation from '../components/analytics/cards/OutletResignation';
import { getLastThreeMonths } from '../util';

const ViewOutletStats: FunctionComponent = () => {
    const { displayName } = useParams();
    const { data, fetchData } = useViewOutletStatsData();
    const months = getLastThreeMonths();

    useEffect(() => {
        if (!displayName) return;
        fetchData(displayName);
    }, [displayName]);

    return (
        <Analytics
            title="Kennzahlen"
            subtitle={displayName}
        >
            <OutletCustomerFeedback
                data={data.feedback}
                title="Kundenfeedback"
                subtitle={`${data.feedbackCount} Bewertungen`}
            />
            <LastThreeAudits
                data={data.audits.map((a) => ({ rating: a.value }))}
                labels={months}
            />
            <ProductivityLastMonths
                data={data.productivity.map((p) => p.value)}
                labels={months}
            />
            <OutletResignation
                selected={displayName || ''}
                data={data.resignation.map((r) => r.value)}
                labels={months}
            />
        </Analytics>
    );
};

export default ViewOutletStats;
