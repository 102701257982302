import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useState } from 'react';
import ShopRevenueGraph, { IntervalOption } from '../components/analytics/cards/ShopRevenueGraph';
import { useShopRevenueData } from '../context/KPIMarketing/ShopRevenueProvider';
import LastTwoShopRevenueInterval from '../components/analytics/cards/LastTwoShopRevenueInterval';

ChartJS.register(LineElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels, annotationPlugin);
const KPIMarketingShopRevenue: React.FC = () => {
    const { data, fetchData } = useShopRevenueData();
    const [selectedInterval, setSelectedInterval] = useState<IntervalOption>({ label: 'Woche', value: 'weekly' });

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <ShopRevenueGraph
                data={data[selectedInterval.value]}
                onIntervalChange={setSelectedInterval}
                title={`Shop Umsatz (${selectedInterval.label})`}
                chosenInterval={selectedInterval}
            />
            <LastTwoShopRevenueInterval
                title="letzten 2 Stunden"
                data={data.hourly.slice(-2)}
                lastIntervalWord="Letze Stunde"
                currentIntervalWord="Aktuelle Stunde"
            />
            <LastTwoShopRevenueInterval
                title="letzten 2 Tage"
                data={data.daily.slice(-2)}
                lastIntervalWord="Gestern"
                currentIntervalWord="Heute"
            />
            <LastTwoShopRevenueInterval
                title="letzten 2 Wochen"
                data={data.weekly.slice(-2)}
                lastIntervalWord="Letze Woche"
                currentIntervalWord="Diese Woche"
            />
        </>
    );
};

export default KPIMarketingShopRevenue;
