import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const FeedbackComparison: React.FC<GraphAnalyticsCardProps<IOutletFeedback[]>> = ({ data }) => {
    const chartData: ChartData<'bar'> = {
        labels: data.map((feedback) => feedback.displayName),
        datasets: [
            {
                data: data.map((feedback) => {
                    const count = Object.keys(feedback.feedbackScores).length;
                    const total = Object.values(feedback.feedbackScores).reduce((acc, curr) => acc + curr[curr.length - 1].value, 0);
                    return total / count;
                }),
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };
    const chartOptions: ChartOptions<'bar'> = {
        plugins: {
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
                formatter(value) {
                    return `${value.toFixed(0)}%`;
                },
            },

            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard title="Kundenfeedback">
            <Bar
                data={chartData}
                options={chartOptions}
            />
        </AnalyticsCard>
    );
};

export default FeedbackComparison;
