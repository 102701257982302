import { PropsWithChildren } from 'react';
import { IShopRevenueData } from '../../types/kpiMarketing/shopRevenue';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchShopRevenueData } from '../../api/kpiMarketing/shopRevenue';

const initialData: IShopRevenueData = {
    monthly: [],
    weekly: [],
    daily: [],
    hourly: [],
    wasFetched: false,
};

const ShopRevenueDataContext = ProviderBuilder.buildContext<IShopRevenueData>();

const ShopRevenueDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const kpiMarketingDataValue = ProviderBuilder.buildProvider<IShopRevenueData>(initialData, fetchShopRevenueData);

    return <ShopRevenueDataContext.Provider value={kpiMarketingDataValue}>{children}</ShopRevenueDataContext.Provider>;
};

const useShopRevenueData = ProviderBuilder.buildUseHook(ShopRevenueDataContext, 'useShopRevenueData');

export default ShopRevenueDataProvider;
export { useShopRevenueData, ShopRevenueDataContext };
