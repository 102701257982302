import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { fetchOutletViewStats } from '../api/outletStats';
import { IViewOutletStatsData } from '../types/kpiQuality/outletComparison';

const initialData: IViewOutletStatsData = {
    feedback: {},
    audits: [],
    productivity: [],
    resignation: [],
    feedbackCount: 0,
    wasFetched: false,
};

const ViewOutletStatsDataContext = createContext<ViewOutletStatsProviderType | undefined>(undefined);

const ViewOutletStatsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [dataState, setDataState] = useState<IViewOutletStatsData>(initialData);

    const fetchData = useCallback(async (displayName: string): Promise<void> => {
        const res = await fetchOutletViewStats(displayName);
        res.wasFetched = true;
        setDataState(res);
    }, []);

    const clearData = useCallback(() => {
        setDataState(initialData);
    }, []);

    const setData = useCallback((data: IViewOutletStatsData) => {
        setDataState(data);
    }, []);

    const dataValue = useMemo(
        () => ({
            data: dataState,
            setData,
            clearData,
            fetchData,
        }),
        [dataState, setData, clearData, fetchData],
    );

    return <ViewOutletStatsDataContext.Provider value={dataValue}>{children}</ViewOutletStatsDataContext.Provider>;
};

const useViewOutletStatsData = (): ViewOutletStatsProviderType => {
    const contextValue = useContext(ViewOutletStatsDataContext);
    if (contextValue === undefined) {
        console.error(`useViewOutletStatsData must be used within a ViewOutletStatsProvider`);
        throw new Error(`useViewOutletStatsData must be used within a ViewOutletStatsProvider`);
    }
    return contextValue;
};

export default ViewOutletStatsProvider;
export { useViewOutletStatsData, ViewOutletStatsDataContext };

type ViewOutletStatsProviderType = {
    data: IViewOutletStatsData;
    setData: (data: IViewOutletStatsData) => void;
    clearData: () => void;
    fetchData: (displayName: string) => Promise<void>;
};
