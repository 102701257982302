import { FunctionComponent } from 'react';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const StayAndBookingPerPhone: FunctionComponent<GraphAnalyticsCardProps<{ stays: number; revenue: number; bookings: number }>> = ({
    title,
    subtitle,
    data,
}) => {
    return (
        <AnalyticsCard
            title={title || 'Feedback'}
            subtitle={subtitle}
        >
            <div className="flex justify-evenly gap-x-12 px-8">
                <div className="flex-1">
                    <h2 className="mb-4 font-medium text-2xl">Telefonische Buchungen</h2>
                    <div className="flex gap-4 justify-between text-xl">
                        <h3 className="">Buchungen</h3>
                        <p className="font-medium">{data.stays}</p>
                    </div>
                    <div className="flex gap-4 justify-between text-xl">
                        <h3>Umsatz</h3>
                        <p className="font-medium">{data.revenue}</p>
                    </div>
                </div>
                <div className="flex-1">
                    <h2 className="mb-4 font-medium text-2xl">Aufenthalte</h2>
                    <div className="flex gap-4 justify-between text-xl">
                        <h3>Stattgefunden</h3>
                        <p className="font-medium">{data.bookings}</p>
                    </div>
                </div>
            </div>
        </AnalyticsCard>
    );
};

export default StayAndBookingPerPhone;
