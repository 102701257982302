import { OutletSalesByWeekResponse, Timeslot, BookingsByOutletResponse, GetDiscountsResponse } from '@mywellness-gmbh/backend-types';
import { backendConnector, sendGetRequest } from '..';

export const fetchOutletSalesComparisonData = async (token: string): Promise<IOutletSalesComparisonData> => {
    const outletSalesData = await sendGetRequest<OutletSalesByWeekResponse>(
        token,
        `/statistics/outletSalesByWeek?timeslot=${Timeslot.lastMonth}`,
    );
    const salesMap = outletSalesData.result.reduce(
        (acc, curr) => {
            if (!acc[curr.displayName]) {
                acc[curr.displayName] = 0;
            }
            acc[curr.displayName] += curr.sales;
            return acc;
        },
        {} as Record<string, number>,
    );
    const labels = Object.keys(salesMap);
    const data = Object.values(salesMap).map((sales) => parseFloat(sales.toFixed(2)));
    return {
        labels,
        data,
    };
};

export const fetchOutletCustomerFeedbackData = async (token: string): Promise<IOutletFeedback[]> => {
    backendConnector.setJwt(token);
    const outletFeedbackData = await backendConnector.fetch('statistics/outletCustomerFeedback [Get]', {
        queryParameters: {
            timeslot: Timeslot.threeMonths,
        },
    });

    return outletFeedbackData.result;
};

const fetchBookingData = async (token: string): Promise<OutletBookingsData[]> => {
    const bookingsByOutletData = sendGetRequest<BookingsByOutletResponse>(
        token,
        `/statistics/bookingsByOutlet?timeslot=${Timeslot.lastMonth}`,
    );
    const sales = fetchOutletSalesComparisonData(token);
    const [bookingsByOutlet, outletSalesData] = await Promise.all([bookingsByOutletData, sales]);
    return bookingsByOutlet.result.map((booking) => {
        const { displayName, outletId, viaPhone, bookings } = booking;
        const salesIndex = outletSalesData.labels.findIndex((label) => label === displayName);
        return {
            displayName,
            outletId,
            viaPhone,
            bookings,
            revenue: outletSalesData.data[salesIndex],
        };
    });
};
export const fetchCRMComparisonData = async (token: string): Promise<ICRMComparisonData> => {
    const discountsByOutletData = sendGetRequest<GetDiscountsResponse>(token, `/statistics/outletDiscounts?timeslot=${Timeslot.lastMonth}`);
    const [outletFeedbackData, bookingsByOutlet, outletDiscounts] = await Promise.all([
        fetchOutletCustomerFeedbackData(token),
        fetchBookingData(token),
        discountsByOutletData,
    ]);
    return {
        feedback: outletFeedbackData,
        feedbackCount: outletFeedbackData.map((feedback) => feedback.feedbackCount),
        bookingsByOutlet,
        outletDiscounts: outletDiscounts.result,
        wasFetched: true,
    };
};
