import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import CustomerDataProvider from './context/CustomerDataProvider';
import UserProvider from './context/UserProvider';
import RoutesProvider from './context/RouteProvider';
import SalesVolumeDataProvider from './context/KPIMarketing/SalesVolumeProvider';
import BookingRateDataProvider from './context/KPIMarketing/BookingRateProvider';
import FrequencyProvider from './context/KPIMarketing/FrequencyProvider';
import FluctuationPageProvider from './context/KPIPeopleAndCulture/FluctuationProvider';
import OperationsPageProvider from './context/KPIOperations/OperationsProvider';
import KpiMroDataProvider from './context/KPIMro/KPIMroProvider';
import ServiceAuditAnalyticsDataProvider from './context/KPIQMAudit/ServiceAuditAnalyticsProvider';
import CustomerExpAuditAnalyticsDataProvider from './context/KPIQMAudit/CustomerExpAuditAnalyticsProvider';
import OutletComparisonProvider from './context/KPIQuality/KPIOutletComparisonProvider';
import OutletProvider from './context/OutletProvider';
import ViewOutletStatsProvider from './context/ViewOutletStatsProvider';
import CRMComparisonProvider from './context/KPICRM/KPICRMComparisonProvider';
import ShopRevenueDataProvider from './context/KPIMarketing/ShopRevenueProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Router>
        <OutletProvider>
            <UserProvider>
                <OperationsPageProvider>
                    <FluctuationPageProvider>
                        <KpiMroDataProvider>
                            <FrequencyProvider>
                                <CRMComparisonProvider>
                                    <OutletComparisonProvider>
                                        <ShopRevenueDataProvider>
                                            <ViewOutletStatsProvider>
                                                <SalesVolumeDataProvider>
                                                    <BookingRateDataProvider>
                                                        <CustomerDataProvider>
                                                            <ServiceAuditAnalyticsDataProvider>
                                                                <CustomerExpAuditAnalyticsDataProvider>
                                                                    <RoutesProvider>
                                                                        <App />
                                                                    </RoutesProvider>
                                                                </CustomerExpAuditAnalyticsDataProvider>
                                                            </ServiceAuditAnalyticsDataProvider>
                                                        </CustomerDataProvider>
                                                    </BookingRateDataProvider>
                                                </SalesVolumeDataProvider>
                                            </ViewOutletStatsProvider>
                                        </ShopRevenueDataProvider>
                                    </OutletComparisonProvider>
                                </CRMComparisonProvider>
                            </FrequencyProvider>
                        </KpiMroDataProvider>
                    </FluctuationPageProvider>
                </OperationsPageProvider>
            </UserProvider>
        </OutletProvider>
    </Router>,
);
