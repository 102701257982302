import { LastOutletScoreResponse, OutletScoresByTag, OutletScoresByTagResponse } from '@mywellness-gmbh/backend-types';
import { sendGetRequest } from '..';
import { IAuditAnalytics, TagComparisonData, TagScoresByOutlet } from '../../types/kpiAudit/auditAnalytics';

export const fetchServiceAuditAnalyticsData = async (token: string, auditType = 'service'): Promise<IAuditAnalytics> => {
    const lastOutletScoresRequest = sendGetRequest<LastOutletScoreResponse>(
        token,
        `/statistics/audit/lastOutletScores?auditType=${auditType}`,
    );
    const outletScoresByTagRequest = sendGetRequest<OutletScoresByTagResponse>(
        token,
        `/statistics/audit/outletScoresByTag?auditType=${auditType}`,
    );
    const [lastOutletScores, outletScoresByTag] = await Promise.all([lastOutletScoresRequest, outletScoresByTagRequest]);
    const allOutletScoresByTag = outletScoresByTag.result.reduce(
        (acc, response) => {
            acc[response.outletId] = response;
            return acc;
        },
        {} as Record<number, OutletScoresByTag>,
    );
    // iterating over the response to get a sorted list of unique outlet names
    const outletsWithTagData = Array.from(
        new Set<string>(outletScoresByTag.result.sort((o1, o2) => o1.outletId - o2.outletId).map((o) => o.outletDisplayName)).values(),
    );

    const lastAuditTagComparison: TagComparisonData = {
        labels: outletsWithTagData,
        auditIds: outletScoresByTag.result.map((val) => {
            const tagScores = Object.values(val.scores);
            if (tagScores.length === 0) return null;
            const { auditId } = tagScores[0][tagScores[0].length - 1];
            return auditId;
        }),
        data: outletScoresByTag.result.reduce(
            (acc, val) => {
                const lastTagRatingsForOutlet = Object.entries(val.scores).map(([key, value]) => {
                    const lastRating = value[value.length - 1];
                    return { rating: lastRating.rating, tag: key };
                });
                // sort important for page consistency
                lastTagRatingsForOutlet.sort((a, b) => a.tag.localeCompare(b.tag));
                return lastTagRatingsForOutlet.reduce((subAcc, subVal) => {
                    const alteredSubAcc = subAcc;
                    if (alteredSubAcc[subVal.tag] === undefined) {
                        alteredSubAcc[subVal.tag] = new Array(outletsWithTagData.length).fill(null);
                    }
                    alteredSubAcc[subVal.tag][outletsWithTagData.indexOf(val.outletDisplayName)] = subVal.rating;

                    return alteredSubAcc;
                }, acc);
            },
            {} as Record<string, (number | null)[]>,
        ),
    };

    // reorganizing the outletScoresByTag response to be grouped by tag instead for other graphs
    const tagScoresByOutlet = outletScoresByTag.result.reduce<TagScoresByOutlet>((acc, val) => {
        return Object.entries(val.scores).reduce((subAcc, [tag, score]) => {
            const alteredAcc = subAcc;
            if (alteredAcc[tag] === undefined) {
                alteredAcc[tag] = [];
            }
            alteredAcc[tag].push({
                outletDisplayname: val.outletDisplayName,
                ratings: score.map((rating) => ({ date: new Date(rating.date).getTime(), rating: rating.rating, auditId: rating.auditId })),
            });
            return alteredAcc;
        }, acc);
    }, {});

    return {
        lastOutletScores: lastOutletScores.result,
        outletScoresByTag: allOutletScoresByTag,
        tagScoresByOutlet,
        lastAuditTagComparison,
        wasFetched: true,
    };
};

export const fetchCustomerExpAuditAnalyticsData = async (token: string): Promise<IAuditAnalytics> =>
    fetchServiceAuditAnalyticsData(token, 'customer');
