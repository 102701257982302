import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FunctionComponent, useEffect } from 'react';
import LoadSpinner from '../components/LoadSpinner';
import { useOutletComparisonData } from '../context/KPIQuality/KPIOutletComparisonProvider';
import FeedbackComparison from '../components/analytics/cards/FeedbackComparison';
import LastScoresPerOutlet from '../components/analytics/cards/LastScoresPerOutlet';
import OutletProductivity from '../components/analytics/cards/OutletProductivity';
import ResignationInProbationary from '../components/analytics/cards/ResignationInProbationary';

ChartJS.register(LineElement, BarElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels);

const KPIOutletComparison: FunctionComponent = () => {
    const { data, fetchData } = useOutletComparisonData();

    useEffect(() => {
        fetchData();
    }, []);

    if (!data.wasFetched) {
        return <LoadSpinner />;
    }

    return (
        <>
            <FeedbackComparison
                data={data.feedback}
                fetchData={fetchData}
            />
            <LastScoresPerOutlet
                data={data.audits.slice(-1)[0]}
                labels={data.audits[0].map((audit) => audit.displayName)}
                fetchData={fetchData}
            />
            <OutletProductivity
                labels={(Object.values(data.productivity.data)[0] || []).map((month) => month.month)}
                data={data.productivity.data}
                fetchData={fetchData}
            />
            <ResignationInProbationary
                data={data.resignationInProbationary.data}
                labels={Object.keys(data.resignationInProbationary.data)}
                fetchData={fetchData}
            />
        </>
    );
};

export default KPIOutletComparison;
