import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useRoutes } from '../../context/RouteProvider';

const DotNavIndicator: FunctionComponent = () => {
    const { routes, setCurrentRoute } = useRoutes();

    return (
        <div className="md:sticky px-4 md:px-10 xl:px-20 bottom-0 bg-beige-75 ">
            <div className="flex items-center justify-center flex-wrap gap-4 pt-4 pb-8  border-t border-black-50 flex-1">
                {routes.map((route, index) => (
                    <NavLink
                        className="navlink"
                        id={route.path}
                        to={route.path}
                        key={`route_${route.path}}`}
                        onClick={(): void => {
                            setCurrentRoute(route);
                        }}
                        end
                    >
                        {index + 1}
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export default DotNavIndicator;
